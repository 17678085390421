<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Checkin" :is-app="true" />

	<app-content-body class="badge">

		<div class="badge-summary" :data-theme="theme" v-if="item">

			<app-panel-content>

				<div class="badge-summary-name">{{ item.name }}</div>
				<div class="badge-summary-reference">Ref. {{ item.referenceRandom }}</div>
				<div class="badge-summary-email">{{ item.email }}</div>

			</app-panel-content>

			<div class="badge-summary-type" :style="{backgroundColor: item.type.colour}">{{ item.type.label }}</div>

		</div>

		<div class="badge-addons" v-if="item">

			<app-panel-check :subtext="item.received | localDate('h:mma, dddd')" :disabled="original.badge" :theme="original.badge ? 'green' : false" :text="item.type.name" :icon="received.badge ? 'check' : 'crossOnly'" :active="received.badge" v-on:toggle="onToggleBadge" />

			<app-panel-check :subtext="addon.received | localDate('h:mma, dddd')" :disabled="original.addons[addon.id]" :theme="original.addons[addon.id] ? 'green' : false" v-for="addon in item.addons" :key="addon.id" :text="addon.name" :icon="received.addons[addon.id] ? 'check' : 'crossOnly'" :active="received.addons[addon.id]" v-on:toggle="onToggleAddon(addon.id)" />

		</div>

		<div class="badge-actions" v-if="item">

			<app-button v-if="addonsRemaining || !item.received" text="Confirm" :loading="is.saving" :theme="(changed) ? 'yellow' : 'grey'" :disabled="!changed" v-on:click="onConfirmClick" />
			<app-button :disabled="is.saving" :text="(changed) ? 'Cancel' : 'Close'" :theme="(changed) ? 'red' : 'grey'" v-on:click="onCloseClick" />

		</div>

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				loading: true,
				saving: false
			},
			item: false,
			received: {
				badge: false,
				addons: {}
			},
			original: {}
		}

	},

	created: function() {

		this.$api.get(['checkin/badge', this.$route.params.id]).then(function(json) {

			this.set(json.badge)
			
			this.is.loading = false

		}.bind(this))

	},

	computed: {

		addonsRemaining: function() {

			return this.$_.filter(this.item.addons, function(addon) {

				return addon.received === 0

			}).length

		},

		theme: function() {

			if (this.addonsRemaining && this.item.received) {

				return 'yellow'

			} else if (this.item.received) {

				return 'green'

			} else {

				return false

			}

		},

		changed: function() {

			return !this.$_.isEqual(this.received, this.original)

		}	

	},
	
	methods: {

		set: function(badge) {

			Vue.set(this, 'item', badge)

			this.received.badge = badge.received ? true : false

			this.$_.each(badge.addons, function(addon) {

				Vue.set(this.received.addons, addon.id, addon.received ? true : false)

			}.bind(this))

			this.original = this.$util.copy(this.received)

		},

		onConfirmClick: function() {

			this.is.saving = true

			this.$api.patch(['checkin/badge', this.item.id], this.received).then(function(json) {

				this.set(json.badge)

				this.is.saving = false

			}.bind(this))

		},

		onCloseClick: function() {

			this.$router.push({
				name: 'Checkin.Dashboard'
			})

		},

		onToggleBadge: function() {

			this.received.badge = !this.received.badge
		
		},

		onToggleAddon: function(id) {

			this.received.addons[id] = !this.received.addons[id]
		
		}

	}

}

</script>

<style scoped>

.badge {
	background-color: #fff;
	display: flex;
	padding: 0px!important;
	flex-direction: column;
}

.badge-summary {
	padding-top: 20px;
	background-color: #384352;
	flex-shrink: 0;
}

.badge-summary[data-theme="green"] {
	background-color: #4faa80;
}

.badge-summary[data-theme="yellow"] {
	background-color: #ffbe7a;
}

.badge-summary-name {
	text-align: center;
	font-weight: 400;
	color: #fff;
	font-size: 24px;
}

.badge-summary-reference {
	text-align: center;
	font-weight: 300;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 4px;
}

.badge-summary-email {
	text-align: center;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.75);
	font-size: 14px;
	margin-top: 20px;
}

.badge-summary-type {
	text-align: center;
	padding: 10px 20px;
	font-weight: 400;
	color: #fff;
}

.badge-addons {
	flex-grow: 1;
}

.badge-addons >>> .check {
	padding: 0px 10px;
}

.is-desktop .badge-addons >>> .check:hover {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 10px;
}

.badge-addons >>> .check:first-child {
	margin-top: 10px;
}

.is-desktop .badge-addons >>> .check:hover .check-text,
.badge-addons >>> .check-text {
	color: #333;
}

.badge-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.badge-actions >>> .button {
	width: calc(50% - 10px);
}

</style>
